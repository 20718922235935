.modalWrapper {
  position: relative;
  z-index: 10;
  .themeModal {
    position: absolute;
    width: 100%;
    width: 290px;

    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    overflow: hidden;
    padding-bottom: 0;

    .themeContainer {
      display: flex;
      flex-direction: column;
      padding-left: 20px;
      padding-top: 30px;
      padding-right: 20px;
      padding-bottom: 70px;
      gap: 10px;
      height: calc(51vh);
      overflow: scroll;
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0);
      }
      &:hover {
        &::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
      .title {
        margin-bottom: 5px;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}
