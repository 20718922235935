.card {
  padding: 20px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  transition: border 200ms ease-in-out, box-shadow 200ms ease-in-out;
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-bottom: 15px;
    color: '#000';
  }
  .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .desc {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #777777;
    margin-bottom: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .button {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      background: #f5f5f5;
      border-radius: 6px;
      padding: 6px 16px;
      border: 1px solid #f5f5f5;
      color: #777777;
      transition: all 300ms ease-in-out;

      &:hover {
        background: #f2f2f2;
        border: 1px solid #f2f2f2;
      }
    }

    .applyButton {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      background: #1c86fa;
      border-radius: 6px;
      padding: 6px 16px;
      border: 1px solid #1c86fa;
      color: #ffffff;
      margin-left: 10px;
    }
  }

  &:hover {
    border: 1px solid #dddddd;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
  }

  &.active {
    border: 1px solid #1c86fa;
    position: relative;

    .footer {
      .button {
        color: #1c86fa;
        background: #ffffff;
        border: 1px solid #1c86fa;
      }
    }

    .selectedIcon {
      position: absolute;
      width: 24px;
      height: 24px;
      top: -10px;
      right: -10px;
    }
  }

  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
    & > * {
      user-select: none;
      pointer-events: none;
    }
  }
}
