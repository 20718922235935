.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}

.prev {
  left: 8px;

  @media only screen and (min-width: 1200px) {
    left: 4.4%;
  }
  @media only screen and (min-width: 1440px) {
    left: 8.3%;
  }
  @media only screen and (min-width: 1920px) {
    left: 17%;
  }
}
.next {
  right: 8px;

  @media only screen and (min-width: 1200px) {
    right: 4.4%;
  }
  @media only screen and (min-width: 1440px) {
    right: 8.3%;
  }
  @media only screen and (min-width: 1920px) {
    right: 17%;
  }
}
