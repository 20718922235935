$padding: 25px 35px;
$icon-size: 100px;

.modal {
  .header,
  .content,
  .footer {
    padding: $padding;
  }

  .modal-icon {
    width: $icon-size;
    height: $icon-size;
    background: #f6ebff;

    &.modal-icon-mini {
      width: $icon-size / 2;
      height: $icon-size / 2;
      border-radius: 42.5%;

      background: linear-gradient(
        227.6deg,
        #d6d3e3 3.18%,
        rgba(204, 197, 202, 0) 101.16%
      ) !important;
    }
  }
  .long {
    max-height: calc(100vh - 6rem) !important;
  }
}
