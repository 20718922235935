.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  .inputWrapper {
    display: flex;
    flex-direction: column;
    position: relative;


    .input {
      --placeholderColor: '';
      border: 1px solid #dddddd;
      border-radius: 6px;
      padding: 9px 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      width: 100%;

      &::placeholder {
        color: var(--placeholderColor);
      }

      &:focus {
        border-color: #1c86fa;
        outline: 0;
      }

      &:not(.hoverEffectNone):hover {
        outline: 3px solid rgba(28, 134, 250, 0.15);
      }
    }

    .copying {
      background-color: #e8f7ee;
    }

    .error {
      border-color: #ea0f0f;

      &:focus {
        border-color: #ea0f0f;
      }

      &:hover {
        outline: 3px solid rgba(234, 15, 15, 0.15);
      }
    }

    .suffix {
      display: flex;
      position: absolute;
      bottom: 8.55px;
      right: 10px;
      color: #777777;
      font-size: 14px;
    }

    .copyButton {
      display: flex;
      position: absolute;
      bottom: 9.75px;
      right: 9.75px;
      cursor: pointer;
    }

    .copyIcon {
      filter: invert(52%) sepia(0%) saturate(2654%) hue-rotate(4deg) brightness(88%) contrast(85%);
    }
  }

  .placeholderLabel {
    position: absolute;
    left: 6px;
    top: 1.3px;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #d2dae3;
    pointer-events: none;
  }

  .inputWrapperRelative {
    position: relative;

    .input {
      padding-right: 40px;
    }
  }

  .inputWrapperPrefix {
    @apply inline-flex flex-row border rounded-md border-solid border-[#DDDDDD];

    .prefix {
      @apply ml-1 my-1 p-[5px] bg-bluelight text-bluesmart rounded-sm pointer-events-none cursor-default leading-[1.125rem] text-base font-inter;
      background-color: #eaf4ff;

      &.error {
        background-color: #fff5ed;
        color: #fc933a;
      }

      &.success {
        background-color: #e8f7ee;
        color: #13af4f;
      }
    }

    input {
      @apply w-full my-[9px] outline-transparent mr-[5px] ml-[9px];
    }
  }

  .errorContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 18px;
    margin: 7px 0;

    .icon {
      margin-right: 4px;
      filter: invert(13%) sepia(87%) saturate(4766%) hue-rotate(354deg) brightness(102%) contrast(108%);
    }

    .message {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #ea0f0f;
    }
  }

  .xs {
    .input {
      @apply text-xs;

      &::placeholder {
        @apply text-xs;
      }
    }
  }
}