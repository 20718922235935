@-webkit-keyframes zoom_in {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@keyframes zoom_in {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

.pps-zoom_in_effect {
  -webkit-animation: zoom_in 1s linear both;
  animation: zoom_in 1s linear both;
}
