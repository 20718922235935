.wrapper {
  @apply pb-20 mt-5 sm:mt-0 px-4 sm:px-0 w-full;

  .gridWrapper {
    @apply grid auto-rows-max;
    gap: 20px;

    @media only screen and (min-width: 640px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    @media only screen and (min-width: 768px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media only screen and (min-width: 1200px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    @media only screen and (min-width: 1400px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }

  .card {
    @apply relative block;
    overflow: hidden;
    aspect-ratio: 3 / 2;
    position: relative;
    border-radius: 13px;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .hover {
      @apply flex flex-col items-center justify-center gap-[8px] cursor-pointer bg-transparent top-0 bottom-0 left-0 right-0 absolute w-full h-full rounded-[13px];
      .selectButton {
        @apply opacity-0 transition-opacity duration-300 ease-in-out text-white rounded-[6px] w-[120px] bg-[#1C86FA] border border-bluesmart text-[11px] 2xl:text-[12px] py-[7px] 2xl:py-[8px] hover:opacity-80;
      }

      .detailButton {
        @apply opacity-0 transition-opacity duration-300 ease-in-out text-white rounded-[6px] w-[120px] bg-transparent border border-white text-[11px] 2xl:text-[12px] py-[7px] 2xl:py-[8px] hover:opacity-80;
      }

      .deviceContainer {
        display: none;
        position: absolute;
        right: 5px;
        top: 5px;
      }
    }

    &:hover .hover {
      @apply bg-black bg-opacity-75;
      .deviceContainer {
        display: block;
      }
      button {
        @apply opacity-100;
      }
    }

    &.active {
      @apply border-2 border-bluesmart rounded-md;

      .tick {
        position: absolute;
        top: 15px;
        right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #1c86fa;
        border-radius: 4px;
        color: white;
      }
    }
  }
}
