.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  .typeButtons {
    display: flex;
    align-items: center;
    font-family: 'Inter';
    font-size: 12px;
    line-height: 16px;
    color: #777777;
    width: 100%;
    .buttonContent {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 0px 14px;
      height: 50px;
      border: 1px solid #eaeaea;
      border-right: none;
      background-color: #fff;
      gap: 4px;

      .label {
        margin-left: 8px;
        width: max-content;
      }
    }

    .buttonContent.first {
      border-radius: 8px 0px 0px 8px;
    }
    .buttonContent.last {
      border-radius: 0px 8px 8px 0px;
      border-right: 1px solid #eaeaea;
    }

    &.active {
      color: white;
      .buttonContent {
        background-color: #1c86fa;
        border-color: #1c86fa;

        svg {
          path {
            stroke: #fff;
          }
        }
      }

      .buttonContent.first {
        border-right-color: #eaeaea;
      }

      .buttonContent.last {
        border-left-color: #eaeaea;
      }
    }
  }
}
