.elementsWrapper {
  position: relative;
  .start,
  .end {
    position: absolute;
    height: 100%;
    z-index: 22;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .end {
    top: 0;
    right: 0;
  }
  .start::after {
    height: 100%;
    width: 70px;
    content: '';
    pointer-events: none;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 40%,
      hsla(0, 0%, 100%, 0) 100%
    );
  }

  .end::before {
    height: 100%;
    width: 70px;
    content: '';
    pointer-events: none;
    background: linear-gradient(270deg, #fff 40%, hsla(0, 0%, 100%, 0) 100%);
  }
}
