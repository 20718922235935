.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
}
.container h1 {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
  line-height: 1.5rem;
}
.container .iconContainer {
  position: relative;
  margin-bottom: 1.25rem;
  border-radius: 9999px;
  border-width: 1px;
  width: 64px;
  height: 64px;
}
.container .iconContainer svg {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.container .connectedStore {
  display: flex;
  width: 100%;
  border-radius: 9999px;
  border-width: 1px;
  gap: 0.375rem;
  background: #e8f7ee;
  padding: 6px;
  margin-top: 15px;
  border: 1px solid #9fc7af;
}
.container .connectedStore span {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.container .button {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
  line-height: 1rem;
  height: 2rem;
  border-radius: 0.375rem;
  background: #f5f5f5;
  margin-top: 20px;
  color: black;
}
.container .button:hover {
  background-color: #eaeaea;
}
