.bottomContainer {
  @apply w-full flex justify-between  py-4  px-[45px] bg-pagebg;
  @media only screen and (max-width: 414px) {
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    & > * {
      margin-bottom: 10px;
    }
  }
  a {
    color: #000;
  }
}
