.top_label {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  margin-bottom: 10px;
}
.input_wrapper {
  @apply relative inline-block bg-white;
  input {
    @apply w-full bg-transparent;
    &[type='number'] {
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
    &::placeholder {
      color: #9198ac;
    }
    &:focus::placeholder {
      color: transparent;
    }
    &:not(:disabled):focus {
      & + label {
        @apply text-bluesmart px-[5px] top-0 text-xs h-[14px];
        &:before {
          content: '';
          @apply w-full left-0 bg-white h-[7px] absolute bottom-0 -z-10;
        }
      }
    }
    &:disabled {
      @apply bg-gray-100 cursor-not-allowed text-gray-700;
      & + label {
        @apply text-gray-700 cursor-not-allowed;
        &:before {
          @apply bg-gray-100;
        }
      }
    }

    &.error {
      @apply border-redcold;

      &:focus {
        @apply border-red;
        & + label {
          @apply text-red;
        }
      }
      &:hover {
        @apply border-red;
        box-shadow: 0 0 0 5px rgb(234 15 15 / 17%);
      }
    }
  }
  .input_indicator {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 10px;
    line-height: 16px;
    font-size: 13px;
    opacity: 0.3;
  }
  label {
    @apply absolute left-[15px] top-1/2 -translate-y-1/2 transition-all font-inter text-base leading-lg pointer-events-none text-gray-600;
    &.label_top {
      @apply px-[5px] top-0 text-xs h-[14px];
      &:before {
        content: '';
        @apply w-full left-0 bg-white  h-[7px] absolute bottom-0 -z-10;
      }
    }
  }
  .disabled_svg {
    @apply absolute right-3 top-1/2 -translate-y-1/2 cursor-not-allowed;
  }

  &.small {
    input {
      @apply text-xs leading-[18px] py-[8px] px-[10px] rounded-md;

      &:hover {
        box-shadow: none !important;
      }

      &:not(:disabled):focus {
        & + label {
          @apply left-[8px] text-[10px];
        }
      }
    }

    label {
      @apply left-[12px] text-sm;
      &.label_top {
        @apply px-[5px] left-[8px] text-[10px] font-semibold text-gray-700;
      }
    }
  }

  &.targeting_style {
    @apply bg-transparent;
    input {
      @apply border-none rounded-none text-xs leading-[16px] px-3 py-[10px];
      &:hover {
        box-shadow: none !important;
      }
    }
  }
}
.inside_error {
  @apply relative;
  .error_div {
    @apply mt-0 left-3 right-3 justify-end bg-white absolute top-1/2 -translate-y-1/2 flex items-center flex-row-reverse select-none pointer-events-none;
    svg {
      @apply mr-0 ml-1;
    }
    p {
      @apply font-inter text-xs leading-[16px] text-red;
    }
  }
}
.error_div {
  @apply mt-3 flex items-center;
  svg {
    @apply mr-1;
  }
  p {
    @apply font-inter text-xs leading-lg text-red;
  }
}
