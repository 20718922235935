.addDomainModal {
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    padding: 15px 30px;
    border-bottom: 1px solid #eaeaea;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
  .formContainer {
    display: flex;
    flex-direction: column;
    padding: 30px;
    .buttonContainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 12px;
    }
  }
}
