.custom_date {
  @apply w-full h-full;
  .rdrDateDisplayWrapper {
    @apply bg-white;
    .rdrDateDisplay {
      @apply m-0 px-8 pt-8 pb-6;
      .rdrDateInput {
        @apply relative rounded-none bg-white shadow-none border-none;
        .rdrWarning {
          @apply hidden;
        }
        &:first-child,
        &:last-child {
          &::after {
            @apply font-inter text-xs absolute left-[15px] bg-white -top-[6px] leading-md px-[5px] text-gray-700;
          }
        }
        &:first-child {
          &::after {
            @apply content-['Start\_date'];
          }
        }
        &:last-child {
          &::after {
            @apply content-['End\_date'];
          }
        }
        &:hover {
          &::after {
            @apply text-bluesmart;
          }
        }
        input {
          @apply h-auto text-left py-2 pl-3 pr-3 font-inter text-sm leading-lg rounded-lg border border-solid border-gray-400 outline-none transition-all duration-200;
          &:focus {
            @apply border-bluesmart;
          }
          &:hover {
            @apply border-bluesmart;
          }
        }
      }
    }
  }
  .rdrMonthAndYearWrapper {
    @apply pt-0 px-8 h-auto mb-4;
    .rdrMonthAndYearPickers {
      @apply font-inter text-base leading-lg text-black font-normal;
    }
    .rdrPprevButton,
    .rdrNextButton {
      @apply m-0 bg-no-repeat bg-center bg-cover;
      i {
        @apply hidden;
      }
    }
    .rdrPprevButton {
      @apply bg-[url('/assets/icons/left-icon.svg')];
    }
    .rdrNextButton {
      @apply bg-[url('/assets/icons/right-icon.svg')];
    }
  }
  .rdrMonths.rdrMonthsVertical {
    .rdrMonth {
      @apply w-full p-0;
      .rdrWeekDays {
        @apply pt-0 px-8 pb-2 flex items-center border-b border-gray-400;
        .rdrWeekDay {
          @apply flex items-center justify-center h-8 font-inter text-sm leading-lg text-gray-700 font-normal;
        }
      }
      .rdrDays {
        @apply py-0 px-8 flex items-center mt-2 mb-2;
        .rdrDay {
          @apply h-8 mb-3 font-inter text-sm leading-md text-black;
          span,
          .rdrDayNumber {
            @apply font-inter text-sm leading-md text-black z-[1];
            span {
              @apply text-black;
              &::after {
                @apply content-none;
              }
            }
          }
          .rdrInRange {
            @apply bg-bluelight h-8 inset-0;
          }
          .rdrDayInPreview {
            @apply bg-bluelight h-8 inset-0 border-none;
          }
          .rdrDayStartPreview {
            @apply bg-bluelight h-8 w-8 inset-0 border-none left-1/2 -translate-x-1/2;
            &::before {
              @apply w-8 h-8 absolute left-1/2 -z-[1] bg-bluelight content-[''];
            }
            &::after {
              @apply content-[''] absolute inset-[-2px] h-[36px] w-[36px] rounded-full border-2 border-solid border-white;
            }
          }
          .rdrDayEndPreview {
            @apply bg-bluelight h-8 w-8 inset-0 border-none left-1/2 -translate-x-1/2;
            &::before {
              @apply content-[''] w-8 h-8 absolute right-1/2 -z-[1] bg-bluelight;
            }
            &::after {
              @apply content-[''] absolute inset-[-2px] h-[36px] w-[36px] rounded-full border-2 border-solid border-white;
            }
          }
          .rdrStartEdge + .rdrDayEndPreview {
            @apply opacity-0;
          }
          .rdrDayStartPreview.rdrDayEndPreview {
            &::before {
              content: none;
            }
          }
          .rdrStartEdge {
            @apply top-0 left-1/2 bottom-0 rounded-full w-8 h-8 -translate-x-1/2;
            & + .rdrDayNumber,
            & + span + .rdrDayNumber {
              span {
                @apply text-white;
              }
            }
            & + .rdrDayStartPreview {
              @apply opacity-0;
            }
            &::before {
              @apply content-[''] w-8 h-8 absolute left-1/2 -z-[1] bg-bluelight;
            }
            &::after {
              @apply content-[''] w-8 h-8 absolute left-0 z-[3] rounded-full bg-bluesmart;
            }
          }
          .rdrEndEdge {
            @apply top-0 left-1/2 bottom-0 rounded-full w-8 h-8 -translate-x-1/2;
            & + .rdrDayNumber,
            & + span + .rdrDayNumber {
              span {
                @apply text-white;
              }
            }
            & + .rdrDayStartPreview {
              @apply opacity-0;
            }
            &::before {
              @apply content-[''] w-8 h-8 absolute right-1/2 -z-[1] bg-bluelight;
            }
            &::after {
              @apply content-[''] w-8 h-8 absolute left-0 z-[3] rounded-full bg-bluesmart;
            }
          }
          .rdrStartEdge.rdrEndEdge {
            z-index: 11;
            & + .rdrDayNumber {
              span {
                color: white !important;
              }
            }
            &::before {
              content: none;
            }
            & + .rdrDayStartPreview,
            & + .rdrDayEndPreview {
              opacity: 1;
              &::after {
                content: none;
              }
              & + .rdrDayNumber {
                span {
                  color: white !important;
                }
              }
            }
          }
        }
      }
    }
  }

  &.time_select {
    .rdrDateDisplayWrapper {
      .rdrDateDisplay {
        @apply m-0 px-8 pt-8 pb-[68px];
      }
    }
  }
}
.rdrDayNumber {
  z-index: 11 !important;
}
