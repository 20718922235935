.buttonContainer {
  display: flex;
  position: relative;
  z-index: 10;
  margin-top: 0.75rem;
  color: #fff;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 38px;
  padding: 2px;
}

.buttonContainer::before {
  transition: opacity 150ms linear;
  content: '';
  z-index: -1;
  position: absolute;
  left: 0;
  transition-property: opacity;
  width: 100%;
  height: 100%;
  border-radius: 0.375rem;
  background: var(--bgColor);
}

.buttonContainer:hover .childrenContainer {
  filter: drop-shadow(0 0 1px rgba(71, 71, 71, 0.313));
}

.buttonContainer:hover::before {
  opacity: 0.9;
}

.buttonContainer .iconContainer {
  position: relative;
  background-color: white;
  left: 0;
  flex: 0;
  padding: 0.5rem;
  border-radius: 0.25rem;
}

.buttonContainer .childrenContainer {
  flex: 1 1 0%;
  justify-content: center;
  display: flex;
  font-size: 0.75rem;
  line-height: 1rem;
  width: calc(100% - 32px);
  text-align: center;
}

@media (min-width: 1024px) {
  .buttonContainer .childrenContainer {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

.buttonContainer button,
.buttonContainer span {
  font-size: 0.75rem;
  line-height: 1rem;
}

@media (min-width: 1024px) {

  .buttonContainer button,
  .buttonContainer span {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}