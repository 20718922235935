.generalMobileView {
  box-sizing: border-box;
  align-self: center;
  width: 100%;

  [data-frame],
  [data-inline-editor] {
    font-size: 14px !important;
  }

  .react-tel-input {
    .selected-flag {
      transform: scale(1) !important;
    }
    .flag {
      transform: scale(0.85) !important;
    }
  }
}
