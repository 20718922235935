.themeItemContainer {
  display: flex;
  width: 100%;
  position: relative;
  cursor: pointer;
  .theme {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 11px 16.13px;
    width: 100%;
    gap: 6px;
    border-radius: 9px;
    overflow: hidden;
    .name {
      font-weight: 800;
      font-size: 14px;
      line-height: 18px;
      color: #fff;
    }
    .fontFamily {
      font-family: 'Inter';
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.5px;
      color: #fff;
    }
  }
  .selectThemeButtonWrapper {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 9px;
    overflow: hidden;
    .selectTheme {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      background: #ffffff;
      border-radius: 6px;
      padding: 8px 7.64px;
    }
  }
  &:hover {
    .selectThemeButtonWrapper {
      display: flex;
    }
  }
}

.themeItemContainer.mediumSize {
  .theme {
    padding: 15px 22.31px;
    gap: 4px;
    .name {
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
    }
  }
}
