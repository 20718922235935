.timeline {
  @apply mb-6;
}
.header {
  @apply flex flex-col items-start justify-center;
  &::after {
    @apply content-[""] flex h-4 w-full ml-[10px] border-l border-bluesmart;
  }
  .container {
    @apply flex flex-row items-start justify-center;
    .icon {
      @apply flex items-center justify-center w-5 h-5 bg-bluesmart rounded-lg mr-2 text-white;
    }
    .label {
      @apply flex justify-start items-center font-inter text-xs font-medium text-gray-1000;
    }
  }
}

.content {
  @apply flex flex-col justify-center items-start pb-[10px];

  .container {
    @apply flex items-start;
    .icon {
      @apply mr-2 opacity-50;
    }
    .label {
      @apply font-inter font-normal text-xs leading-4 text-[#777777];
    }
  }
  .subContent {
    @apply flex flex-col justify-center items-start pl-4 text-xs leading-4 text-[#777777];
  }
}
