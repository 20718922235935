.checkbox {
  display: flex;
  align-items: center;
  user-select: none;
  padding: 6px;
  padding-left: 0px;

  &.disabled {
    cursor: not-allowed;
  }
  input {
    appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 1px solid rgb(221 221 221);
    transition: all 200ms ease-in-out;
    cursor: pointer;
    flex: none;

    &:not(:disabled):hover {
      border-color: rgb(28 134 250);
      box-shadow: 0 0 0 2px rgb(28 134 250 / 17%);
    }
    &:checked {
      border-color: rgb(28 134 250);
      background-color: rgb(28 134 250);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      background-image: url(/assets/icons/add-icon-2.svg);

      & + .label {
        color: black;
      }
    }

    &.error {
      border-color: rgb(221 167 167);
      &:not(:disabled):hover {
        border-color: rgb(234 15 15);
        box-shadow: 0 0 0 2px rgb(234 15 15 / 17%);
      }
    }

    &:disabled {
      border-color: rgb(210 218 227);
      background-color: rgb(245 247 248);
      cursor: not-allowed;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      background-image: url(/assets/icons/disabled-icon.svg);
    }
  }
  .label {
    font-family: 'Inter';
    font-size: 14px;
    line-height: 18px;
    color: rgb(119 119 119);
    margin-left: 10px;
    cursor: pointer;
  }
  .disabled {
    cursor: not-allowed !important;
  }
  &.checkbox_error {
    .label {
      color: #d92020;
    }
  }
  &.small {
    input {
      width: 18px;
      height: 18px;
    }
  }
  &.targeting_style {
    padding: 6px 0px;
    input {
      width: 16px;
      height: 16px;
    }
    .label {
      font-family: 'Inter';
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      margin-left: 6px;
      cursor: pointer;
    }
  }
}
