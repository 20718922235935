.container {
  @apply w-full;
}

.inputWrapper {
  @apply relative w-full;
}

.inputWrapperRelative {
  @apply relative;
}

.label {
  @apply block mb-2 text-sm font-medium text-gray-700;
}

.editor {
  @apply min-h-[450px];
  :global {
    div[class*='TiptapEditor_customToolbar'] {
      @apply flex items-center gap-2 p-2 ml-2 mt-2;
   
      
      button {
        @apply p-1.5 rounded hover:bg-[#F3F4F6] transition-colors;
        min-width: 28px;
        height: 28px;
        
        svg {
          @apply w-4 h-4;
          path {
            @apply fill-[#374151];
          }
        }
        
        &.active {
          @apply bg-[#1c86fa];
          svg path {
            @apply fill-white;
          }
        }
      }

      .smartTagButton,
      .unsubscribeButton {
        @apply bg-gray-100 px-2 py-1 rounded-md;
        button:focus {
          color: unset;
        }
      }
    }

    .ProseMirror {
      @apply min-h-[450px] p-3 outline-none border-none;
      font-family: 'Inter', sans-serif;
      border-top: 1px solid #E5E7EB !important;
      
      p {
        @apply m-0 leading-relaxed;
      }
      
      a {
        @apply text-[#2563EB] underline;
      }
    }
  }
}

.disabled {
  @apply opacity-50 cursor-not-allowed;
  
  :global {
    .ProseMirror, .customToolbar button {
      @apply pointer-events-none;
    }
  }
}

.error {
  @apply flex items-center gap-1 mt-1 text-sm text-[#EF4444];
  
  svg {
    @apply w-4 h-4;
  }
}
