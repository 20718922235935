.drawer {
  display: flex;
  flex-direction: column;
  width: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  box-shadow: -5px 0px 15px rgba(0, 0, 0, 0.1);
  background: #fff;
  transition: width ease-in-out 150ms;
  z-index: 9999;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #eaeaea;
    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      padding: 23px 25px;
    }
    .close {
      display: flex;
      margin: 23px 18px;
    }
  }
}
.open {
  width: 100%;

  @media only screen and (min-width: 640px) {
    width: 420px;
  }
}
