.favicon_wrapper {
  @apply relative w-6 h-6 flex items-center justify-end overflow-hidden;
  svg {
    @apply w-full h-full;
  }
  img {
    @apply max-w-[24px] aspect-square bg-white relative object-cover object-top;
  }

  &.small {
    @apply w-[18px] h-[18px];
    img {
      @apply max-w-[18px];
    }
  }
}
