.thumbnail {
  position: relative;
  border-radius: 13px;

  &.selected {
    background: #f5f5f5;
    &:before {
      content: '';
      top: -5px;
      left: -5px;
      right: -5px;
      bottom: -5px;

      position: absolute;
      border-radius: 13px;
    }
    border: 1px solid #1c86fa;

    .templateName {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.card {
  position: relative;
  display: block;
  overflow: hidden;
  aspect-ratio: 3 / 2;
  position: relative;
  border-radius: 13px;
  .image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    object-position: center center;
    border-radius: 10px;
  }

  .hover {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    background-color: transparent;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    border-radius: 13px;
    .buttons {
      display: flex;
      gap: 10px;
    }
    .selectButton {
      opacity: 0;
      transition: opacity 300ms ease-in-out;
      color: white;
      border-radius: 6px;
      // width: 120px;
      border: 1px solid #fff;
      font-family: 'Inter';
      font-size: 12px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 20px;
      padding-right: 20px;
      &:hover {
        opacity: 1;
      }
    }
    &:hover {
      .selectButton {
        opacity: 0.5;
        &:hover {
          opacity: 1;
        }
      }
    }
    .detailsButton {
      opacity: 0;
      transition: opacity 300ms ease-in-out;
      color: white;
      border-radius: 6px;
      // width: 120px;
      background-color: #1c86fa;
      border: 1px solid #1c86fa;
      font-family: 'Inter';
      font-size: 12px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 20px;
      padding-right: 20px;

      &:hover {
        opacity: 0.8;
      }
    }

    .deviceContainer {
      display: none;
      position: absolute;
      right: 5px;
      top: 5px;

      &.hidden {
        display: none !important;
      }

      .deviceWrapper {
        display: flex;

        .typeButtons {
          display: flex;
          align-items: center;
          gap: 8px;
          font-family: 'Inter';
          font-size: 12px;
          line-height: 20px;
          font-weight: 600;
          color: #777777;

          &.default {
            margin-right: 5px;
            width: 30px;
            height: 26px;
            background-color: white;
            justify-content: center;
            border-radius: 8px;
          }
          &.deviceButton {
            display: flex;
            padding-left: 8px;
            padding-right: 8px;
            gap: 8px;
            min-width: 30px;
            height: 26px;
            background-color: white;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
          }
        }
      }
    }
  }

  &:hover .hover {
    background-color: rgba(0, 0, 0, 0.75);
    .deviceContainer {
      display: block;
    }
    button {
      opacity: 100;
    }
  }

  &.selected {
    position: relative;
    overflow: visible;
    aspect-ratio: unset;
    padding: 20px;
    padding-bottom: 10px;

    .selectedBadge {
      position: absolute;
      top: 8px;
      right: 8px;

      background: #1c86fa;
      border-radius: 6px;
      padding: 7px 25px;
      font-family: 'Inter';
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: #ffffff;
    }

    .image {
      position: relative;
      width: 65%;
      height: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      object-position: center center;
      border-radius: 10px;
    }
  }
}

.isAnimatedTooltip {
  top: auto !important;
  bottom: -15px;
  transform: translateY(51%) !important;
  right: -18px !important;
  left: auto !important;
}
.templateName {
  font-family: 'Inter';
  margin-top: 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

.templateSubTag {
  color: #777;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 116.667% */
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 10px;
}
