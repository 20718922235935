.wrapper {
  @apply flex items-center justify-between relative;
  .label {
    @apply font-inter font-normal text-base leading-lg text-black mr-4 select-none;
  }
  &.reverse {
    @apply flex-row-reverse;
    .label {
      @apply mr-0 ml-4;
    }
  }
  .switch_wrapper {
    --color: #777777;
    label {
      @apply cursor-pointer w-[44px] h-[24px] bg-transparent border border-[var(--color)] block rounded-3xl relative transition-all duration-300;
      &::after {
        @apply content-[""] absolute top-[3px] left-[3px] w-4 h-4 bg-[var(--color)] rounded-full transition-all duration-300;
      }
    }
    input[type='checkbox'] {
      @apply absolute hidden invisible;

      &:checked + label {
        @apply bg-bluesmart border-bluesmart;
        &::after {
          @apply left-[calc(100%-3px)] -translate-x-full bg-white;
        }
      }
    }
  }
  &.disabled {
    .labelContent {
      @apply opacity-50;
    }
    .switch_wrapper {
      @apply opacity-50;
      label {
        @apply bg-gray-100 border-gray-400 cursor-not-allowed;
        &::after {
          @apply bg-gray-400;
        }
      }
    }
  }

  &.small {
    .label {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }

    .switch_wrapper {
      label {
        @apply w-[36px] h-[20px];
        &::after {
          @apply w-3 h-3;
        }
      }
    }
  }
  &.extraSmall {
    .label {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }

    .switch_wrapper {
      label {
        @apply w-[33px] h-[18px];
        &::after {
          @apply w-3 h-3 top-[2px] left-[2px];
        }
      }
    }
  }
}
