.embedCode {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @apply w-full m-auto;
  .embedCodeHead {
    @apply pl-10 pt-[1.7rem];
  }

  .icon {
    @apply mb-1 border border-pagebg rounded-full p-[16px];
  }
  .title {
    h3 {
      @apply text-center font-inter text-xl md:text-[24px] font-bold leading-[28px] mt-[16px];
    }
  }
  .modalContent {
    @apply px-10;
    .desc {
      @apply mb-5 mt-2 md:mb-4 md:mt-2;
      p {
        @apply font-inter text-[12px] leading-4 font-normal text-[#000000];
      }
    }
    .code {
      margin-bottom: 20px;
      border-radius: 8px;
    }
    .footer {
      p {
        @apply font-inter text-[12px] leading-[18px] font-normal mb-[5px] text-[#000000];
        b {
          font-weight: 600 !important;
        }
      }
    }

    .button {
      @apply flex gap-[10px] mt-5 md:mt-7 mb-[40px];
      button {
        @apply text-[14px] leading-[18px] font-semibold text-[#000000] rounded-md py-3 px-6 max-h-[36px];
      }
    }
  }
}

.tabs_header {
  width: fit-content !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 0px !important;
  display: flex !important;
  align-items: flex-end !important;
  li {
    padding: 10px 28px !important;
    margin-bottom: -1px;
    opacity: 0.4;
    color: #000 !important;
    border-bottom: 2px solid transparent !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 18px !important;
    &:global(.active-item) {
      opacity: 1 !important;
      border-bottom: 2px solid #1c86fa !important;
    }
  }
}
