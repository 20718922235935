.modalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 65px 40px 65px;
  gap: 15px;

  .icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 40px;
    border: 1px solid #eaeaea;

    svg {
      filter: invert(41%) sepia(99%) saturate(3052%) hue-rotate(191deg) brightness(102%) contrast(104%);
    }
  }

  .header {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
  }

  .description {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }
}