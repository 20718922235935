.addWebSiteModal {
  display: flex;
  flex-direction: column;
  .header {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    padding: 15px 30px;
    border-bottom: 1px solid #eaeaea;
  }
  .content {
    display: flex;
    width: 100%;
    padding: 30px 30px 35px 30px;
    .addWebSiteModalForm {
      width: 100%;
      .addNewWebSiteWrapper {
        margin-top: 15px;
      }
      .buttonContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 12px;
      }
    }
  }
}
