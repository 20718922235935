.container {
  z-index: 7;
  @apply relative flex flex-col items-center px-5 py-7;
  .h1 {
    @apply font-inter text-[24px] leading-9  mt-7 mb-4 font-bold text-center dark:text-white;
  }
  :global(.btn) {
    @apply text-[14px] mb-2;
  }
}
