#quill-smart-tag {
  background: #f5f5f5;
  border-radius: 4px;
  padding: 2px 8px 2px 3px;
  margin: 0px 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & * {
    color: #777777 !important;
  }

  & span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &-content {
    svg {
      margin-right: 2px;
    }
  }
}
#quill-unsubscribe {
  font-weight: 600;
}

#rich-text-quill {
  .ql-tooltip {
    padding: 2px 4px !important;
    font-size: 11px !important;
    margin-left: 6px !important;
    left: 0 !important;
    max-width: calc(100% - 12px) !important;

    &::before {
      line-height: 18px !important;
      margin-right: 4px !important;
    }

    .ql-preview {
      max-width: 100% !important;
      line-height: 18px !important;
    }

    input {
      font-size: 11px !important;
      line-height: 18px !important;
      padding: 2px 4px !important;
      max-width: 100% !important;
      width: 100% !important;
    }

    .ql-action {
      &::after {
        margin-left: 6px !important;
      }
    }
  }
}
