@-webkit-keyframes fade_in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade_in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pps-fade_in_effect {
  -webkit-animation: fade_in 1s linear both;
  animation: fade_in 1s linear both;
}
