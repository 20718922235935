.tagButton {
  padding: 4px;
  background-color: transparent;
  border-radius: 4px;
  font-family: 'Inter';
  font-size: 12px;
  line-height: 16px;
  color: #777777;
  align-items: center;
  justify-content: flex-start;
  transition: all 200ms ease-in-out;
  display: inline-flex;
  width: 100%;
  white-space: nowrap;
  @media (min-width: 1024px) {
    display: flex;
  }

  .icon {
    display: flex;
    margin-right: 8px;
  }

  .count {
    @apply rounded-[160px] bg-[#F5F5F5] h-full ml-auto leading-4 px-[6px] min-w-[20px];
  }
  .activeCount {
    @apply bg-white text-[#1C86FA];
  }
  .close {
    display: flex;
    opacity: 0.75;
    margin-left: auto;
  }

  &:hover {
    background-color: #f5f5f5;
    color: #000000;
  }

  &.activeTag {
    background-color: #1c86fa;
    color: #ffffff;
  }
}
