.wrapper {
  @apply flex items-center items-center;
  .label {
    @apply font-inter font-normal text-base leading-lg text-black mr-4 select-none;
  }
  &.reverse {
    @apply flex-row-reverse;
    .label {
      @apply mr-0 ml-4;
    }
  }
  .switch_wrapper {
    label {
      @apply cursor-pointer w-[33px] h-[18px] bg-white border border-gray-700 block rounded-3xl relative transition-all duration-300;
      &::after {
        @apply content-[""] absolute top-[2px] left-[2px] w-[12px] h-[12px] bg-gray-700 rounded-full transition-all duration-300;
      }
    }
    input[type='checkbox'] {
      @apply absolute hidden invisible;

      &:checked + label {
        @apply bg-bluesmart border-bluesmart;
        &::after {
          @apply left-[calc(100%-3px)] -translate-x-full bg-white;
        }
      }
    }
  }
  &.disabled {
    @apply opacity-50;
    .switch_wrapper {
      label {
        @apply bg-gray-100 border-gray-400 cursor-not-allowed;
        &::after {
          @apply bg-gray-400;
        }
      }
    }
  }

  &.small {
    .label {
      @apply text-sm leading-[18px];
    }

    .switch_wrapper {
      label {
        @apply w-[36px] h-[20px];
        &::after {
          @apply w-3 h-3;
        }
      }
    }
  }
  .prefix,
  .suffix {
    @apply font-normal text-sm leading-[18px];
  }
  .prefix {
    @apply mr-[10px];
  }
  .suffix {
    @apply ml-[10px];
  }
  .hidePrefixOnMobile {
    @apply hidden sm:inline-block;
  }
}
