.container {
  display: flex;
  flex-direction: column;
  width: 90%;
  .inputWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 38px;
    align-items: center;
    .input {
      width: 18px;
      height: 18px;
      border-radius: 4px;
      margin-right: 10px;
    }
    .label {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #777777;
    }
    .checkedLabel {
      color: black;
    }
  }
}
