#drop-area {
  position: fixed;
  background-color: #1c86fa !important;
  user-select: none !important;
  pointer-events: none !important;
  transition: all ease-in-out 150ms;
}

[tree-element] {
  position: relative;
}

[dnd-position='top'] {
  &:before {
    content: '';
    position: absolute;
    left: 8px;
    right: 8px;
    top: 1px;
    height: 3px;
    background-color: #1c86fa;
  }
}

[dnd-position='bottom'] {
  &:before {
    content: '';
    position: absolute;
    left: 8px;
    right: 8px;
    bottom: 1px;
    height: 3px;
    background-color: #1c86fa;
  }
}

[dnd-position='center'] {
  background-color: #eaf4ff;
  border: 1px dashed #1c86fa;
}

.dnd-center {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: -1px;
    right: 0;
    bottom: 0;
    top: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border: 3px dashed #1c86fa;
  }
}

.drop-wrapper {
  width: 0px;
  height: 0px;

  &:before {
    content: '';
    position: absolute;
    transition: all ease-in-out 150ms;
  }

  &.top,
  &.bottom,
  &.center {
    &:before {
      width: 100%;
      height: 12px;
      left: 0px;
      right: 0px;
    }
  }

  &.left,
  &.right {
    &:before {
      width: 24px;
      height: 100%;
      top: 0px;
      bottom: 0px;
    }
  }

  &.center {
    width: 100%;
    height: 100%;
    &:before {
      transition-duration: 0ms;
      transition-delay: 200ms;
    }
  }

  &.top {
    &:before {
      top: -6px;
    }
  }

  &.bottom {
    &:before {
      bottom: -6px;
    }
  }

  &.left {
    &:before {
      left: -6px;
    }
  }

  &.right {
    &:before {
      right: -6px;
    }
  }
}

[data-dropzone='true'] {
  &:before {
    border: 2px dashed #1c86fa !important;
    background: rgba(28, 134, 250, 0.15) !important;
  }

  &.top,
  &.bottom {
    &:before {
      height: 36px;
    }
  }

  &.center {
    &:before {
      height: 100%;
    }
  }

  &.top {
    &:before {
      top: 0px;
    }
  }

  &.bottom {
    &:before {
      bottom: 0px;
    }
  }

  &.left {
    &:before {
      left: 0px;
    }
  }

  &.right {
    &:before {
      right: 0px;
    }
  }
}
