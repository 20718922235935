.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #f5f5f5;
  color: '#000';
  margin-right: 6px;
}
.smartModeIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: linear-gradient(142.75deg, #314ddc 7.65%, #f14bff 97.3%);
  margin-right: 6px;
}
.title .titleSmartMode {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 26px);
}

.titleSmartMode {
  font-weight: 500;
}
