 @-webkit-keyframes flip-in-y {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
  }
}
@keyframes flip-in-y {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
  }
}


.pps-flip_in_y_effect {
  -webkit-animation: flip-in-y 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
  animation: flip-in-y 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}
