@-webkit-keyframes bounce_in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3) translate3d(0, 0, 0);
    transform: scale(0.3) translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    -webkit-transform: scale(0.89);
    transform: scale(0.89);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) translate3d(0, 0, 0);
    transform: scale(1) translate3d(0, 0, 0);
  }
}
@keyframes bounce_in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3) translate3d(0, 0, 0);
    transform: scale(0.3) translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    -webkit-transform: scale(0.89);
    transform: scale(0.89);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) translate3d(0, 0, 0);
    transform: scale(1) translate3d(0, 0, 0);
  }
}

.pps-bounce_in_effect {
  -webkit-animation: bounce_in 1s linear both;
  animation: bounce_in 1s linear both;
}
