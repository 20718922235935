.grid {
  display: grid;
  grid-template-columns: 16px 16px 16px;
  gap: 6px;

  .item {
    @apply w-4 h-4 border border-[#dddddd];

    &.active {
      @apply border-bluesmart bg-bluesmart;
    }

    &:disabled {
      @apply bg-gray-400 cursor-not-allowed;
    }
  }

  &.large {
    grid-template-columns: 26px 26px 26px;

    .item {
      @apply w-[26px] h-[18px];
      transition: all 200ms ease-in-out;

      &.active {
        @apply border-bluesmart bg-bluesmart;
      }

      &.withIcon {
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px;
        background-image: url(/assets/icons/add-icon-2.svg);
      }

      &.disabled {
        @apply bg-[#DDDDDD] cursor-not-allowed;
        background-image: unset;
      }
    }
  }
  &.medium {
    gap: 5px;

    grid-template-columns: 24px 24px 24px;

    .item {
      @apply w-[24px] h-[15px];

      &.active {
        @apply border-bluesmart bg-bluesmart;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px;
        &.withIcon {
          background-image: url(/assets/icons/add-icon-2.svg);
        }
      }
      &.disabled {
        background-color: #dddddd;
        border: none !important;
        cursor: not-allowed;
      }
    }
  }

  &.small {
    gap: 4px;

    grid-template-columns: 24px 24px 24px;

    .item {
      @apply w-[24px] h-[14px];

      &.active {
        @apply border-bluesmart bg-bluesmart;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px;
        &.withIcon {
          background-image: url(/assets/icons/add-icon-2.svg);
        }
      }
      &.disabled {
        background-color: #dddddd;
        border: none !important;
        cursor: not-allowed;
      }
    }
  }

  &.imagePosition {
    padding: 6px;
    border-radius: 6px;
    grid-template-columns: 20px 20px 20px;
    gap: 5px;
    border: 1px solid #dddddd;
    .item {
      height: 14px;
      border-radius: 2px;
      width: auto;
      &.active {
        background-repeat: no-repeat;
        background-position: center;
        background-size: 11px;
        &.withIcon {
          background-color: #eaf4ff;
          outline: #1c86fa;
          background-image: url(/assets/icons/active-img-position.svg);
        }
      }
    }
  }
}
