.LoadingWrapper {
  // z-index: 99995;
  @apply fixed top-0 right-0 bottom-0 left-0 flex flex-col justify-center items-center w-screen h-screen;
}
.background {
  z-index: 99990;
  opacity: 0.2;
  @apply bg-black  fixed top-0 right-0 bottom-0 left-0 flex flex-col justify-center items-center w-screen h-screen;
}
.lottieWrapper {
  z-index: 99999;
  @apply rounded-full bg-white p-0;
}
