.container {
  @apply min-h-[33px] border border-gray-250 rounded-md p-1;
  &.focusedInput {
    border-color: #1c86fa;
  }
  &:hover {
    outline: 3px solid rgba(28, 134, 250, 0.15);
  }
  .inner {
    @apply relative flex w-full min-h-[24px];
    .tags {
      @apply flex gap-1 flex-wrap flex-[1_auto];

      .tag {
        @apply pl-1 bg-grayborder rounded-[3px] h-6 flex items-center gap-1;
        &:hover {
          @apply bg-[#e2e2e2];
        }
        span {
          @apply select-none cursor-default text-xs leading-[14px] text-black;
        }
      }
    }
    .input {
      @apply flex-1 text-xs ml-1 w-full appearance-none focus:outline-none;
      &.disablePlaceHolder {
        &::placeholder {
          opacity: 0;
        }
      }
      &::placeholder {
        @apply text-xs;
      }
    }
    .deleteButton {
      svg:hover {
        @apply text-red;
      }
    }
  }
}
