.tooltip {
  display: inline-block;
  position: relative;

  --backgroundColor: black;
  --textColor: white;

  .tooltipContainer {
    z-index: 9;
    max-width: 280px;
    top: -5px;
    left: 50%;
    transform: translate(-50%, -100%);
    word-break: keep-all;
    word-wrap: normal;
    white-space: nowrap;
    user-select: none;
    position: absolute;
    display: none;
    opacity: 0;
    background-color: var(--backgroundColor);
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    padding: 5px 8px;
    color: var(--textColor);
    transition: all 300ms ease-in-out;
  }


  .tooltipContainer__singleLineView {
    z-index: 9;
    top: -5px;
    left: 50%;
    transform: translate(-50%, -100%);
    word-break: keep-all;
    word-wrap: normal;
    white-space: nowrap;
    user-select: none;
    position: absolute;
    display: none;
    opacity: 0;
    transition: all 300ms ease-in-out;


    .singleLineViewName {
      background-color: var(--backgroundColor);
      color: var(--textColor);
      border-radius: 4px;
      padding: 5px 8px;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
    }
  }

  &:hover {
    .tooltipContainer {
      display: block;
      opacity: 100;
    }

    .tooltipContainer__singleLineView {
      display: block;
      opacity: 100;
    }
  }

  &.keepTooltip {
    position: initial;

    .tooltipContainer {
      z-index: 99999;
      position: absolute;
      word-break: break-word;
      word-wrap: break-word;
      height: max-content;
      width: 240px;
      font-weight: normal;
      white-space: pre-wrap;
      top: 50%;
      padding: 0px;
      transform: translateX(-50%);
      background-color: transparent;

      .contentInner {
        padding: 8px;
        border-radius: 4px;
        background-color: black;
      }

      button {
        @apply underline ml-1;
      }
    }
  }

  .tranparentDivider {
    &.top {
      --h: 14px;
    }

    &.bottom {
      --h: 10px;
    }

    &.right {
      --h: 52px;
      --w: 80px;
      position: absolute;
      left: -20%;
    }

    @apply bg-transparent h-[var(--h)] w-[var(--w)];
  }

  .contentButton {
    @apply ml-1 border-b border-black leading-[14px];

    &:hover {
      @apply text-bluesmart border-bluesmart;
    }
  }

  .contentTitle {
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}