@-webkit-keyframes fade_in_down {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fade_in_down {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.pps-fade_in_down_effect {
  -webkit-animation: fade_in_down 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: fade_in_down 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
