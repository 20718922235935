.color_picker,
.hex_color_picker {
  .react-colorful {
    @apply w-full h-[166px];
    .react-colorful__saturation {
      @apply max-h-[140px] rounded-lg mb-[10px] shadow-none rounded-none;
      .react-colorful__saturation-pointer {
        @apply w-5 h-5 border-[3px] border-white;
      }
    }
    .react-colorful__hue {
      @apply h-[10px] rounded-md mx-[15px];
      .react-colorful__hue-pointer {
        @apply w-5 h-5 border-[3px] border-white;
      }
    }
  }
}
