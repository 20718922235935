.selectbox_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  --dropdownWidth: unset;
  :global(.pps-select__menu) {
    min-width: var(--dropdownWidth);
  }
  .label {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 10px;
  }
  .select {
    :global(.pps-select__control) {
      width: 100%;
      border-radius: 6px;
    }
    :global(.pps-select__indicator-separator) {
      display: none;
    }
    :global(.pps-select__placeholder),
    :global(.pps-select__single-value),
    :global(.pps-select__input-container) {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      margin: 0;
      padding: 0;
    }
    :global(.pps-select__menu) {
      z-index: 2;
    }
    :global(.pps-select__menu-list) {
      padding: 5px 0;
      border-radius: 6px;
    }
    :global(.pps-select__option) {
      padding: 7px 15px;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      white-space: nowrap;
    }
    :global(.pps-select__value-container) {
      padding: 8px 10.5px;
    }
    :global(.pps-select__indicator) {
      padding: 0 8px;
      svg {
        @apply fill-gray-700;
      }
    }
  }
  .dropdownIndicatorContainer {
    & path {
      transform-origin: 33%;
      @apply transition-all;
    }

    &.dropdownIndicatorOpenAnimation path {
      @apply rotate-180;
    }
  }
  &.targeting_style {
    .select {
      :global(.pps-select__control) {
        @apply border-none rounded-none text-sm leading-[18px] shadow-none;
      }

      :global(.pps-select__single-value),
      :global(.pps-select__input-container) {
        @apply text-sm leading-[18px];
      }
      :global(.pps-select__indicator) {
        @apply mr-4 w-[18px] h-[18px] text-[#777777];
      }
      :global(.pps-select__menu) {
        @apply mt-2;
        & > div {
          @apply py-2;
        }
      }
      :global(.pps-select__option) {
        @apply px-[12px] py-2  text-sm leading-[18px];
      }
    }
  }
  .errorContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 18px;
    margin: 7px 0;
    .icon {
      margin-right: 4px;
      filter: invert(13%) sepia(87%) saturate(4766%) hue-rotate(354deg)
        brightness(102%) contrast(108%);
    }
    .message {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #ea0f0f;
    }
  }
}
.medium {
  .label {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 10px;
  }
  .select {
    :global(.pps-select__control) {
      width: 100%;
      border-radius: 6px;
      // min-height: 48px;
    }
    :global(.pps-select__placeholder),
    :global(.pps-select__single-value),
    :global(.pps-select__input-container) {
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      margin: 0;
      padding: 0;
    }
    :global(.pps-select__option) {
      font-size: 16px;
      line-height: 18px;
    }
    :global(.pps-select__menu-list) {
      padding: 10px 0;
    }
  }
}
