.btn {
  @apply px-10 py-[14px] font-inter text-base leading-lg font-semibold rounded-md border transition-colors duration-150;
  &-outline {
    @apply bg-white border-gray-400 text-gray-700;
    &:not(:disabled):hover {
      @apply border-black text-black;
    }
  }
  &-outline-black {
    @apply bg-white border-black text-black;
  }
  &-outline-white {
    @apply bg-transparent border-white text-white;
  }
  &-outline-blue {
    @apply bg-white border-bluesmart text-bluesmart;
    &:not(:disabled):hover {
      @apply bg-bluesmart border-bluesmart text-white;
    }
  }
  &-primary {
    @apply bg-bluesmart border-bluesmart text-white;
  }
  &-secondary {
    @apply bg-gray-100 border-gray-100 text-black;
  }
  &-danger {
    @apply bg-redlight border-redlight text-red;
    &:not(:disabled):hover {
      @apply bg-red border-red text-white;
    }

    &-sm {
      font-size: 12px;
      line-height: 18px;
      padding: 12px 40px;
      border-radius: 6px;
    }
  }
  &-black {
    @apply bg-black border-black text-white;
  }
  &-link {
    @apply p-0 font-normal border-0 text-bluesmart;
  }

  &:disabled,
  &.disabled {
    @apply opacity-50 cursor-not-allowed;
  }
}
