.selectbox_wrapper {
  width: 100%;
  .select {
    :global(.pps-select__control) {
      width: 100%;
      border-radius: 6px;
    }
    :global(.pps-select__indicator-separator) {
      display: none;
    }
    :global(.pps-select__placeholder),
    :global(.pps-select__single-value),
    :global(.pps-select__input-container) {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      margin: 0;
      padding: 0;
    }
    :global(.pps-select__indicator) {
      padding: 0 8px;
      svg {
        fill: #000000;
      }
    }
    :global(.pps-select__value-container) {
      @apply py-0 pl-[15px] pr-0 flex flex-nowrap overflow-x-auto text-sm;

      &::-webkit-scrollbar {
        height: 4px;
      }

      &::-webkit-scrollbar-track {
        background: #ffffff;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #717791;
        border-left: 16px solid white;
        border-top: 2px solid white;
      }
    }
  }
}
.isMulti {
  :global(.pps-select__option) {
    padding: 6px 20px;
  }
}

.multipleSelectOption {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkboxWrapper {
  display: flex;
  margin-right: 10px;
  input[type='checkbox'] {
    width: 18px;
    height: 18px;
    border-radius: 4px;
  }
}
.label {
  font-weight: 500;
  font-size: 12px;

  color: #000000;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-height: 18px; /* fallback */
  max-height: 54px; /* fallback */
  text-overflow: ellipsis;
}

.selectAllContainer {
  display: flex;
  flex-direction: row;
  padding: 8px 12px;
  margin-top: 15px;

  .checkboxWrapper {
    display: flex;
    font-size: 12px;

    input[type='checkbox'] {
      margin-right: 10px;
      width: 18px;
      height: 18px;
      border-radius: 4px;
    }
  }
}
.menuListContainer {
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 640px) {
    max-height: 33vh;
  }
  .divider {
    margin: 10px 0 !important;
  }
  .footer {
    display: flex;
    flex-direction: column;
    .buttonContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 6px 19px;
      margin-bottom: 7px;
      .clearAll {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        cursor: pointer;
      }
      .close {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        cursor: pointer;
      }
    }
  }
}

.valueContainer {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.errorContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 18px;
  margin: 7px 0;
  .icon {
    margin-right: 4px;
    filter: invert(13%) sepia(87%) saturate(4766%) hue-rotate(354deg)
      brightness(102%) contrast(108%);
  }
  .message {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #ea0f0f;
  }
}
