.tox-toolbar__group {
  .tox-tbtn__select-chevron {
    display: none !important;
  }
  .tox-tbtn:not(:last-child) {
    height: 26px !important;
    margin-right: 5px !important;
    box-sizing: content-box;
  }

  .tox-tbtn--enabled {
    background: #eaf4ff !important;
    color: #1c86fa !important;
  }
  .tox-tbtn--enabled:hover {
    background-color: #187fed !important;
  }
  .tox-tbtn:not(.tox-tbtn--enabled):hover {
    cursor: pointer !important;
    span {
      pointer-events: none !important;
    }
    background-color: #eaf4ff !important;
    color: #1c86fa !important;
    border-radius: 4px !important;
    svg {
      fill: #1c86fa !important;
    }
    img {
      filter: invert(38%) sepia(86%) saturate(3307%) hue-rotate(202deg)
        brightness(109%) contrast(96%) !important;
    }
  }

  .tox-tbtn--enabled {
    background-color: #1c86fa !important;
    color: white !important;
    * {
      cursor: pointer !important;
    }
    img {
      filter: invert(100%) sepia(0%) saturate(7493%) hue-rotate(274deg)
        brightness(102%) contrast(103%) !important;
    }
  }
}
.tox-tinymce {
  border: 1px solid #dddddd !important;
}
.tox-editor-header {
  box-shadow: none !important;
  border-bottom: 1px solid #dddddd !important;
}
.tox-toolbar__group {
  padding: 2px 12px 2px 12px !important;
}
.tox-toolbar-overlord {
  border-bottom: 0px !important;
}
.tox-toolbar__primary {
  background: transparent !important;
}
.tox .tox-collection--list .tox-collection__item {
  cursor: pointer !important;
  &:hover {
    background-color: #1c87fa26;
    color: white;
  }
}

.tox-dialog-wrap {
  * {
    font-family: 'Inter', sans-serif !important;
  }

  .tox-dialog__header {
    padding: 12px 12px 12px 20px !important;
    border-bottom: 1px solid #eaeaea !important;

    .tox-dialog__title {
      color: #000 !important;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 700 !important;
      line-height: 24px !important;
    }

    button[title='Close'] {
      width: 24px !important;
      height: 24px !important;
      display: flex !important;
      padding: 5px !important;
      align-items: flex-start !important;
      gap: 10px !important;
      border-radius: 160px !important;
      background: #999 !important;
      &:hover {
        opacity: 0.9;
      }
      svg {
        fill: white !important;
        scale: 0.69 !important;
        transform: translate(-8px, -8px) !important;
      }
    }
  }

  .tox-dialog__body-content {
    .tox-form {
      display: flex !important;
      padding: 0px 20px !important;
      flex-direction: column !important;
      align-items: flex-start !important;
      gap: 20px !important;
      align-self: stretch !important;

      .tox-form__group {
        display: flex !important;
        flex-direction: column !important;
        align-items: flex-start !important;
        gap: 6px !important;
        align-self: stretch !important;
        width: 100% !important;
      }
    }

    .tox-form__controls-h-stack,
    .tox-listboxfield {
      width: 100% !important;
    }

    .tox-label {
      color: #000 !important;
      font-size: 10px !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: 12px !important;
    }
  }

  .tox-dialog__footer {
    .tox-button[disabled] {
      background-color: #777;
    }

    .tox-button {
      display: flex !important;
      padding: 8px 25px !important;
      align-items: flex-start !important;
      gap: 12px !important;
      border-radius: 6px !important;
      border: unset !important;
      color: #fff !important;
      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 600 !important;
      line-height: 16px !important;
      background-color: #777;

      &.tox-button--secondary {
        color: #000 !important;
        background: #f5f5f5 !important;
      }
    }

    button[title='Save'] {
      background-color: #1c86fa !important;
    }

    padding: 11px 35px 30px 37px !important;
  }
}
