.container {
  @apply pb-[20px] pt-[40px] px-[50px];
}
.contentContainer {
  .badge {
    @apply ml-auto flex  rounded-full border border-green;
    &.verifiedBadge {
      @apply border-green;
    }
    &.unverifiedBadge {
      @apply border-orange;
    }
    .status {
      @apply flex items-center p-[7px] pr-[12px];
      .icon {
        @apply mr-[7px];
        &.verifiedIcon {
          @apply text-green;
        }
        &.unverifiedIcon {
          @apply text-orange;
        }
      }
      .label {
        @apply font-[400] text-xs leading-[14px] text-black;
      }
    }
  }
}

.shopifyContentContainer {
  @apply py-[40px] px-[50px] rounded-[22px] border border-black/10 bg-[url(/assets/images/shopify-bg-green.png)] bg-cover;

  .header {
    @apply flex justify-center;
    .icon {
      @apply mb-1 border border-pagebg rounded-full p-[16px];
    }
  }
}

.wordpressContentContainer {
  @apply py-[40px] px-[50px] rounded-[22px] border border-black/10 bg-[url(/assets/images/wordpress-bg-grey.jpg)] bg-cover;

  .header {
    @apply flex justify-center;
    .icon {
      @apply mb-1 border border-pagebg rounded-full p-[16px];
    }
  }
}

.bottomContainer {
  @apply w-full flex justify-between  py-4  px-[45px] bg-pagebg;
  @media only screen and (max-width: 414px) {
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    & > * {
      margin-bottom: 10px;
    }
  }
  a {
    color: #000;
  }
}

.badge {
  @apply ml-auto flex  rounded-full border border-green;
  &.verifiedBadge {
    @apply border-green;
  }
  &.unverifiedBadge {
    @apply border-orange;
  }
  .status {
    @apply flex items-center p-[7px] pr-[12px];
    .icon {
      @apply mr-[7px];
      &.verifiedIcon {
        @apply text-green;
      }
      &.unverifiedIcon {
        @apply text-orange;
      }
    }
    .label {
      @apply font-[400] text-xs leading-[14px] text-black;
    }
  }
}
