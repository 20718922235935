.container {
  --overlayColor: black;

  display: flex;
  padding: 40px 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 14px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  align-self: stretch;
  max-width: 520px;
  border-radius: 22px;
  border: 1px solid rgb(233, 233, 233);
  background: linear-gradient(320deg, #fff 35%, rgba(255, 255, 255, 0) 100%),
    url(/assets/images/gridbackground.png),
    linear-gradient(140deg, var(--overlayColor) 0%, rgba(149, 191, 71, 0) 23%),
    #fff;
  background-size: 100%;

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
  }
  h1 {
    color: var(--black, #000);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    max-width: 220px;
    line-height: 24px;
  }

  .iconContainer {
    display: flex;
    .circleIcon {
      position: relative;
      margin-bottom: 1.25rem;
      border-radius: 9999px;
      border-width: 1px;
      background-color: white;
      width: 64px;
      height: 64px;
      svg {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }
      &:last-child {
        transform: translateX(-0.625rem);
      }
    }
  }
}
