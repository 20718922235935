@import '~react-date-range/dist/styles.css'; // main css file
@import '~react-date-range/dist/theme/default.css'; // theme css file
@import '~ol/ol.css';
@import '~react-toastify/dist/ReactToastify.css';
// Import Swiper styles
@import 'swiper/css';
@import 'swiper/css/pagination';
@import 'swiper/css/navigation';

.sortable-ghost {
  .tree_item {
    background-color: #eaf4ff !important;
  }
}
