.filterButton {
  display: flex;
  align-items: center;
  gap: 6px;
  height: 32px;
  padding-left: 12px;
  padding-right: 9px;
  background-color: white;
  border: 1px solid rgb(233 237 241);
  border-radius: 6px;
  color: rgb(119 119 119);
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .label {
    font-family: 'Inter';
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    color: rgb(119 119 119);
    white-space: nowrap;
    margin-right: 8px;
    user-select: none;
  }

  &.active {
    color: black;
    .label {
      color: black;
    }
  }
}
.checkbox_custom {
  padding: 0px !important;

  input {
    width: 16px !important;
    height: 16px !important;
    border-radius: 4px !important;
  }
}
