.container {
  z-index: 7;
  @apply flex flex-col px-[115px] pt-[50px] pb-[60px];

  .formContainer {
    @apply flex flex-col mt-[18px] gap-[18px];
  }
  @apply relative flex flex-col justify-center items-center;
  .buttonContainer {
    @apply flex flex-row justify-between max-w-[370px] w-full gap-[15px];
  }
  .button {
    @apply text-sm py-[14px] px-[30px] whitespace-nowrap;
  }
}

.confetti {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
