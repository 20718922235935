.tox .tox-tbtn:hover {
  cursor: pointer !important;
  background-color: #1c86fa !important;
}

.tox .tox-tbtn:hover svg {
  fill: #ffff !important;
}

.tox .tox-toolbar__group:not(:last-of-type) {
  border: none !important;
}

.tox .tox-tbtn--select:hover {
  color: #ffff !important;
}
.tox-toolbar-overlord {
  display: flex !important;
  flex: 0 0 auto !important;
  flex-shrink: 0 !important;
  flex-wrap: wrap !important;
  border-bottom: 1px solid #a1b4d4 !important;
  padding-top: 2px !important;
  padding-bottom: 1px !important;
}
.tox-toolbar {
  background: none !important;
}
.tox-tinymce {
  border-color: #a1b4d4 !important;
  border-radius: 8px !important;
}
.tox-edit-area {
  border-color: #a1b4d4 !important;
  min-height: 260px !important;
  iframe {
    min-height: 260px !important;
  }
}
.tox .tox-menu {
  max-height: 200px !important;
  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.1);
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }
}
