.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 70px 100px;
  background: url(/assets/images/shopify-connect-your-account-card-bg.webp)
    white;
  background-repeat: no-repeat;
  background-size: cover;

  .iconContainer {
    div {
      width: 4em;
      height: 4em;
      background: #fff;
    }
  }

  .title {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    margin: 15px 0 10px 0;
  }

  .subTitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 25px;
  }

  .steps {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .stepContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .stepNumber {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border: 1px solid #dddddd;
        border-radius: 16px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
      }

      .stepText {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;

        a {
          color: #1c86fa;
          font-weight: 600;
        }
      }

      .hint {
        width: 18px;
        height: 18px;
        cursor: pointer;
        svg {
          filter: invert(47%) sepia(0%) saturate(931%) hue-rotate(170deg)
            brightness(98%) contrast(87%);
        }
      }
    }
  }

  .status {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    padding: 7px 15px 7px 7px;
    border-radius: 32px;
    gap: 5px;
    margin-top: 25px;

    .label {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
    }
  }

  .idle {
    display: none;
  }

  .loading {
    background: #f5f5f5;
    border: 1px solid #dddddd;
  }

  .success {
    background: #e8f7ee;
    border: 1px solid #13af4f;

    .icon {
      svg {
        filter: invert(40%) sepia(100%) saturate(1813%) hue-rotate(118deg)
          brightness(100%) contrast(85%);
      }
    }
  }

  .error {
    background: #fff5ed;
    border: 1px solid #fc933a;

    .icon {
      svg {
        fill: #fc933a;
      }
    }
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
  }
}
