.accordion {
  display: flex;
  flex-direction: column;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  margin-bottom: 10px;
  transition: box-shadow ease 0.5s;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .title {
      display: flex;
      padding: 24px;
    }
    .arrow {
      margin: 24px;
      transition: transform ease 0.5s;
      svg {
        filter: invert(48%) sepia(1%) saturate(856%) hue-rotate(336deg)
          brightness(95%) contrast(84%);
      }
    }
    .arrowOnTop {
      @media only screen and (max-width: 1024px) {
        align-items: flex-start;
        margin-top: 22px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
    .arrowHiddenOnMobile {
      @media only screen and (max-width: 640px) {
        display: none;
      }
    }
  }
  .arrowOnTop {
    @media only screen and (max-width: 1024px) {
      align-items: flex-start;
    }
  }
  .content {
    display: flex;
    height: 0;
  }
}
.active {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  .header {
    border-bottom: 1px solid #dddddd;
    .arrow {
      transform: rotate(180deg);
      transition: transform ease 0.5s;
    }
  }
  .content {
    height: auto;
    overflow-y: scroll;
     }
   transition: box-shadow ease 0.5s;
}

.small {
  .header {
    .title {
      padding: 9px;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
    }
    .arrow {
      margin: 9px;
      margin-top: 9px;
      margin-bottom: 9px;
    }
  }
}
