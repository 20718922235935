.navigationWrapper {
  grid-template-columns: 1fr 3fr;
  @media only screen and (max-width: 1280px) {
    grid-template-columns: 2fr 3fr;
  }
  @media only screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    max-width: 908px;
  }

  .toggleFilterMenuButton {
    @apply sm:hidden cursor-pointer rounded-[6px] bg-[#F5F5F5] flex justify-center items-center w-[36px] h-[36px];
    svg {
      path {
        fill: #333;
      }
    }
    &.active {
      @apply bg-[#1C86FA];
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
}

.closeBtn {
  @apply flex items-center z-20 duration-300;

  @media (max-width: 1440px) {
    p {
      @apply hidden;
    }
  }
}

.filterButton {
  @apply flex items-center gap-2 font-inter text-[12px] leading-[18px] font-semibold text-gray-700 h-[36px] px-[12px] border border-solid bg-white	border-gray-200 rounded-[8px] whitespace-nowrap;
  span {
    @apply flex items-center justify-center;
  }
  p {
    @apply absolute translate-y-10  px-0.5 py-1.5 hidden w-max rounded-lg mt-2 md:relative md:translate-y-0 md:py-0 md:mt-0 md:rounded-none md:hidden lg:block;
  }
  &:hover {
    p {
      @apply lg:block;
    }
  }
  &.active {
    @apply text-black;

    span {
      @apply bg-white;
    }
  }
}

.category_buttons {
  @apply h-12 flex  font-inter text-xs leading-5 font-semibold rounded-lg   transition-all  xl:text-base md:text-[11px];

  &.active {
    @apply text-black;
  }
}

.template_menu::before {
  @apply md:content-[''] md:absolute xl:w-40 md:w-28 md:pt-9 md:border-solid md:border-[#D2DAE3] md:border-b-2;
}

.tamplate_menu_button::before {
  @apply content-[''] absolute  pt-9 border-solid border-[#000000] border-b-2 block;
}

.dropdown:hover .dropdown_menu {
  display: block;

  .category_buttons {
    @apply text-[14px] py-[9px] pl-[15px];
  }
}
.headSubSearchInput {
  max-width: 315px;
  height: 36px;
  border-radius: 12px;
  padding-right: 15px;
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
}
.navigation {
  @apply container flex items-center justify-between;

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    max-width: 908px;
  }
}

.playbookWrapper {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 71px);

  .playbookSidebar {
    display: flex;
    max-width: 292px;
    width: 100%;

    .playbookScrollWrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
    }
    .goalSelectorContainer {
      // overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 0 !important;
      }
    }
  }

  .templatesContainer {
    width: calc(100vw - 300px);
    padding: 15px 30px;
    overflow: auto;
    border-left: 1px solid rgb(234 234 234);
  }
}
.showcaseWrapper {
  display: flex;
  width: 100%;
  // height: calc(100vh - 71px);

  @apply relative gap-[30px] mt-[40px];
  @media only screen and (min-width: 640px) {
    gap: 20px;
  }

  @media screen and (max-width: 1024px) {
    display: grid;
    gap: 0;
  }

  .templatesContainer {
    width: calc(100vw - 300px);
    padding: 15px 30px;
    overflow: auto;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
  .showcaseSidebar {
    @apply lg:sticky lg:top-[90px] lg:h-max w-full lg:max-w-[292px];

    .showcaseScrollWrapper {
      @apply pb-8 lg:pb-12;
      @media only screen and (min-width: 1024px) {
        @apply h-[calc(100vh-149px)];
        overflow-y: scroll;
        padding-bottom: 0;
        &::-webkit-scrollbar {
          width: 0 !important;
        }
      }
      @media screen and (max-width: 1024px) {
        margin-top: 65px;
      }
    }
  }
}
.playbook {
  .goalSelector {
    display: flex;
    background: #fff;
    box-shadow: 0 4px 10px rgb(0 0 0 / 25%);
    border-radius: 6px;
    position: relative;
    box-shadow: none;
    width: 100%;
    .tabHeader {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2px;
      border-radius: 4px;
      background: #f5f5f5;
      margin-bottom: 0;
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1;
        & > span {
          padding: 4px 12px !important;
        }
        margin: 0 !important;
        font-weight: 400 !important;
        font-size: 12px;
        line-height: 18px;
        color: #777;
        text-transform: capitalize;
        border-radius: 4px;
      }
    }
    .activeTabHeader {
      background: #fff;
      box-shadow: 0px 3px 4px 0px #0000001a;
      color: #000 !important;
    }
  }
  // .tagsContainer {
  //   &::-webkit-scrollbar {
  //     // width: 0 !important;
  //   }
  // }
}

.templatesContainer {
  @apply w-full;
}

.templateSelectionContainer {
  @media only screen and (max-width: 640px) {
    width: calc(100vw - 40px) !important;
  }
}
