.top_label {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  margin-bottom: 10px;
}
.selectbox_wrapper {
  &.grayBackground {
    .select {
      :global(.pps-select__control) {
        @apply bg-pagebg border-pagebg shadow-none;
      }
    }
  }
  .select {
    :global(.pps-select__control) {
      @apply min-h-[48px] rounded-md border-[#dddddd] font-inter;
    }

    :global(.pps-select__value-container) {
      @apply py-0 pl-[15px] pr-0 flex-nowrap overflow-x-auto;

      &.withIcon {
        @apply flex gap-[7px];
      }

      &::-webkit-scrollbar {
        height: 4px;
      }

      &::-webkit-scrollbar-track {
        background: #ffffff;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #717791;
        border-left: 16px solid white;
        border-top: 2px solid white;
      }
    }

    :global(.pps-select__single-value),
    :global(.pps-select__input) {
      @apply font-inter text-base leading-lg m-0 p-0 text-black;
    }

    :global(.pps-select__multi-value) {
      @apply min-w-[auto];
      div {
        @apply font-inter text-xs leading-xl font-medium;
      }
    }

    :global(.pps-select__indicator-separator) {
      @apply hidden;
    }

    :global(.pps-select__indicator) {
      @apply p-0 mr-3 ml-1 text-black w-6 h-6  bg-no-repeat bg-center bg-cover transition-transform hidden;
      background-image: url(/assets/icons/down-icon.svg);
      svg {
        @apply hidden;
      }
      &:last-child {
        @apply block;
      }
    }

    :global(.pps-select__menu) {
      @apply w-auto min-w-full mt-[10px] mb-3 rounded-md z-[12];
      box-shadow: 0px 1px 5px rgb(0 0 0 / 15%);
      & > div {
        @apply py-[10px] rounded-lg border-none;
        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-track {
          background: #ffffff;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #d0cdcd !important;
          border-radius: 50px;
          border: none !important;
        }
      }
    }

    :global(.pps-select__option) {
      @apply px-[15px] py-[11px] font-inter text-base leading-lg cursor-pointer whitespace-nowrap;
    }

    &.menu_open {
      :global(.pps-select__indicator) {
        @apply rotate-180;
      }
    }
    &.error {
      :global(.pps-select__control) {
        @apply border-redcold;

        &:focus {
          @apply border-red;
          & + label {
            @apply text-red;
          }
        }
        &:hover {
          @apply border-red;
          box-shadow: 0 0 0 5px rgb(234 15 15 / 17%);
        }
      }
    }
  }
  &.label_select {
    .select {
      :global(.pps-select__placeholder) {
        @apply opacity-0;
      }
    }
  }
  .label {
    @apply absolute left-[15px] right-[15px] pr-3 top-1/2 -translate-y-1/2 transition-all font-inter text-base leading-lg pointer-events-none truncate text-gray-600;
    &.label_top {
      @apply px-[5px] top-0 text-xs h-[14px] max-w-fit;
      &:before {
        content: '';
        @apply w-full left-0 bg-white  h-[8px] absolute bottom-0 -z-10;
      }
      &.focus {
        @apply text-bluesmart;
      }
    }
  }
  .error_div {
    @apply mt-3 flex items-center;
    svg {
      @apply mr-1;
    }
    p {
      @apply font-inter text-xs leading-lg text-red;
    }
  }
  &.disabled {
    div {
      @apply bg-gray-100 cursor-not-allowed text-gray-700 rounded-md;
      .label {
        @apply text-gray-700 cursor-not-allowed;
        &:before {
          @apply bg-gray-100;
        }
      }
    }
  }

  &.small {
    .select {
      :global(.pps-select__control) {
        @apply min-h-[36px] font-inter text-xs leading-[18px];

        &:hover {
          box-shadow: none !important;
        }
      }
      :global(.pps-select__value-container) {
        @apply pl-[12px];
      }
      :global(.pps-select__single-value),
      :global(.pps-select__input-container) {
        @apply text-xs leading-[18px];
      }
      :global(.pps-select__indicator) {
        @apply mr-2 w-[18px] h-[18px];
      }
      :global(.pps-select__menu) {
        @apply mt-2;
        & > div {
          @apply py-2;
        }
      }
      :global(.pps-select__option) {
        @apply px-[12px] py-2 text-xs leading-[18px];
      }
    }

    .label {
      @apply left-[12px] right-[12px] text-sm;
      &.label_top {
        @apply px-[5px] left-[8px] text-[10px] font-semibold text-gray-700;
      }
    }
    &.placeholderBlack {
      :global(.pps-select__placeholder) {
        color: #000;
        margin-left: 0;
      }
    }
  }

  &.xsmall {
    .select {
      :global(.pps-select__control) {
        @apply min-h-[32px] font-inter text-xs leading-[18px];

        &:hover {
          box-shadow: none !important;
        }
      }
      :global(.pps-select__value-container) {
        @apply pl-[12px];
      }
      :global(.pps-select__single-value),
      :global(.pps-select__input-container) {
        @apply text-xs leading-[18px];
      }
      :global(.pps-select__indicator) {
        @apply mr-2 w-[18px] h-[18px];
      }
      :global(.pps-select__menu) {
        @apply mt-2;
        & > div {
          @apply py-2;
        }
      }
      :global(.pps-select__option) {
        @apply px-[12px] py-2 text-xs leading-[18px];
      }
    }

    .label {
      @apply left-[12px] right-[12px] text-sm;
      &.label_top {
        @apply px-[5px] left-[8px] text-[10px] font-semibold text-gray-700;
      }
    }
    &.placeholderBlack {
      :global(.pps-select__placeholder) {
        color: #000;
        margin-left: 0;
      }
    }
  }

  &.targeting_style {
    .select {
      :global(.pps-select__control) {
        @apply border-none rounded-none text-xs leading-[16px] shadow-none py-[10px] min-h-[36px];
      }
      :global(.pps-select__value-container) {
        flex-wrap: wrap !important;
        padding-left: 12px;
      }
      :global(.pps-select__single-value),
      :global(.pps-select__input-container) {
        @apply text-xs leading-[16px] p-0 m-0;
      }
      :global(.pps-select__indicator) {
        @apply mr-4 w-[16px] h-[16px] text-[#777777];
      }
      :global(.pps-select__menu) {
        @apply mt-2;
        & > div {
          @apply py-2;
        }
      }
      :global(.pps-select__option) {
        @apply px-[12px] py-2 text-xs leading-[16px];
      }

      &.error {
        :global(.pps-select__control) {
          &:hover {
            @apply shadow-none;
          }
        }
      }
    }
    .error_div {
      @apply hidden;
    }

    &.error {
      :global(.pps-select__placeholder) {
        color: #d92020 !important;
        padding-right: 22px;
        display: inline-block;
        width: fit-content;
        background-image: url(/assets/icons/error_outline_red.svg);
        background-size: 18px 18px;
        background-position: center right;
        background-repeat: no-repeat;
      }
    }
  }

  &.analytics_style {
    .select {
      :global(.pps-select__control) {
        @apply min-h-[36px] font-inter text-xs;
      }
      :global(.pps-select__value-container) {
        @apply pl-[12px];
      }
      :global(.pps-select__single-value) {
        @apply text-xs;
      }
      :global(.pps-select__input-container) {
        @apply text-xs ml-0;
      }
      :global(.pps-select__indicator) {
        @apply mr-2 w-5 h-5;
      }
      :global(.pps-select__menu) {
        @apply mt-2;
        & > div {
          @apply py-2;
        }
      }

      :global(.pps-select__option) {
        @apply px-[12px] py-[10px] text-xs;
      }
    }

    .label {
      @apply left-[12px] right-[12px] text-xs;
      &.label_top {
        @apply px-[5px] left-[8px] text-[10px] font-semibold text-gray-700;
      }
    }
  }
  &.segment {
    .select {
      :global(.pps-select__input-container) {
        @apply text-xs p-0 m-0;
      }
    }

    .label {
      @apply left-[12px] right-[12px] text-xs;
      &.label_top {
        @apply px-[5px] left-[8px] text-[10px] font-semibold text-gray-700;
      }
    }
  }

  &.isTypeable {
    .select {
      :global(.pps-select__control) {
        cursor: pointer;
      }
      :global(.pps-select__input) {
        caret-color: transparent;
        pointer-events: none;
      }
    }
  }
}

.isUnitSelect {
  margin: 0;
  min-width: 20px;
  height: 20px;
  border-radius: 3px;

  .select {
    :global(.pps-select__control) {
      background: none !important;
      border: none;
      height: 20px;
      min-height: 20px !important;
      cursor: pointer;
    }
    :global(.pps-select__single-value) {
      font-size: 8px !important;
      text-transform: uppercase;
      padding-bottom: 0px !important;
      transition-property: color, background-color, border-color,
        text-decoration-color, fill, stroke;
      transition-duration: 0.15s;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      line-height: 8px;
      font-weight: 500;
      color: #777;
      border-radius: 3px;
      margin-right: 0;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    :global(.pps-select__value-container) {
      padding-left: 0px !important;

      height: 20px;
    }
    :global(.pps-select__option) {
      padding: 6px 16px !important;
    }
    :global(.pps-select__indicators) {
      @apply hidden;
    }
    :global(.pps-select__menu) {
      left: -34px;
    }
  }
  &.default {
    .select {
      :global(.pps-select__single-value) {
        font-size: 10px !important;
        font-weight: 500 !important;
        color: #000000 !important;
        width: auto !important;
      }
      :global(.pps-select__menu) {
        left: -10px;
      }
      padding-left: 4px;
    }
  }

  &:hover {
    background: #fff !important;
    :global(.pps-select__single-value) {
      color: #000000;
    }
    &.default {
      background: none !important;
    }
  }
  &:focus {
    outline: none;
  }
}

