.preview {
  height: 100%;
  background-image: url('/assets/images/preview_background.png');
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  z-index: 1;
  position: relative;
  font-size: 0.65rem;

  font-size: 14px;

  [data-element-id='teaser'],
  [data-element-id='canvas'] {
    zoom: 0.67;
  }

  @media only screen and (min-width: 576px) {
    font-size: 10px;
  }
  @media only screen and (min-width: 768px) {
    font-size: 11px;
  }
  @media only screen and (min-width: 976px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 1400px) {
    font-size: 16px;
  }

  [data-element='countdown'] {
    font-size: 10px !important;
  }
  * label {
    font-size: var(--bodyTextElementFontSize) !important;
  }
  & > div {
    height: 100%;
  }
  &.isLightingPageType {
    & > div > div {
      height: 100%;
    }
  }

  [data-element='close-button'] {
    cursor: default !important;
    pointer-events: none !important;
  }
  .overlay {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  .mobileOverlay {
    display: none;
  }
  &.mobileView {
    display: flex;
    justify-content: center;
    padding: 14px;
    background-image: unset;
    max-height: 515px;
    width: auto !important;
    aspect-ratio: 1/2;
    font-size: 0.6em;

    .desktopOverlay {
      overflow: hidden;
      display: none;
    }
    .mobileOverlay {
      overflow: hidden;
      display: block;
    }
    .mockup {
      position: relative;
      background-image: url('/assets/images/preview_background.png');
      background-position: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      overflow-y: scroll;
      aspect-ratio: 1/2;
      margin-top: auto;
      margin-bottom: auto;
      border-radius: 24px;
      border: 7px #fff solid;
      scale: 1;
    }

    [data-element='countdown'] {
      font-size: 0.9em !important;
      scale: 0.96;
      & > div {
        --timer-cell-width: 1.5em;
      }
    }
  }
  &.isFullScreenPageType {
    .overlay,
    .desktopOverlay,
    .mobileOverlay {
      display: none;
    }
    font-size: 0.6em;
  }
  .mockup {
    display: flex;
    & > div {
      height: 100%;
    }
  }
}
