.psButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;
  color: #fff;
  background: #1c86fa;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  width: fit-content;
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke,
    -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  .spinnerWrapper {
    margin-right: 10px;
    svg {
      path {
        fill: #fff;
      }
    }
  }
  &:disabled {
    background: #777777;
    cursor: not-allowed;
  }
  .icon {
    margin-right: 10px;
  }

  &.tooltip {
    position: relative !important;
    overflow: visible !important;
  }
  &[type='hidden'] {
    @apply hidden;
  }
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.spinnerWrapper {
  animation: spin 1s linear infinite;
}
.second {
  background: #f5f5f5;
  color: #000;
  .spinnerWrapper {
    svg {
      path {
        fill: #000 !important;
      }
    }
  }
  &:disabled {
    background: #f5f5f5;
  }
}
.danger {
  background: #ea0f0f;
  color: #fff;
  &:disabled {
    background: #ea0f0f;
  }
}
.outline {
  border-width: 1px !important;
  border-color: #1c86fa;
  background-color: #ffffff;
  color: #1c86fa;
}
.ghost {
  border-width: 1px !important;
  border-color: #e9edf1;
  background-color: #fff;
  color: #000;
}
.shopify {
  background: #34785e;
  color: #fff;
}

.outline:not(:disabled):hover {
  border-color: #1c86fa;
  background-color: #1c86fa;
  color: #ffffff;
}
.text {
  justify-content: flex-start;
  align-items: center;
  background: transparent;
  color: #1c86fa;
  padding: 0 !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  .icon {
    margin-right: 5px;
  }
  &:disabled {
    background: #ea0f0f;
  }
}
.block {
  width: 100%;
}
.small {
  padding: 9px 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  border-radius: 6px;
}
.extraSmall {
  padding: 7px 20px;
  @apply h-8 leading-[18px] rounded-md font-semibold text-xs;
}

.gray {
  color: #777777;
}
.black {
  color: black;
}

.haveIcon {
  padding-top: 8px;
  padding-bottom: 8px;
}

.outlineDisabled:disabled {
  border-width: 1px;
  border-color: #ddd;
  background-color: #fff;
  color: #ddd;
}
