.miniCloseButton {
  padding: 8px !important;
  margin-right: 0px !important;

  svg path:first-child {
    fill: #777777;
  }

  .light path:first-child {
    fill: #999999;
  }
}
