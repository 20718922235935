.container {
  @apply rounded-xl overflow-auto min-w-[800px] bg-cover bg-no-repeat;
  background-image: url('/assets/images/explore-shopify.jpg');
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 14px;

  .shopifyButton {
    display: flex;
    padding: 7px 25px;
    border-radius: 6px;
    border: 1px #54753f;
    background: linear-gradient(111deg, #85bc61 6.3%, #5e8e3e 58.83%);
    box-shadow: 2px 2px 4px 0px #a1cd83 inset;
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    margin-left: 7px;
  }
}
