.campaignInfo {
  border-radius: 12px;
  background: #fff;
  border: 1px solid #eaeaea;

  .header {
    color: #000;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px; /* 150% */
    border-bottom: 1px solid #eaeaea;
    padding: 7px 20px;
  }
  .wrapper {
    padding: 24px;
  }
  .body {
    display: flex;
    gap: 15px;
    justify-content: space-between;
  }
  .withBorder {
    padding-bottom: 24px;
    border-bottom: 1px #eaeaea solid;
  }

  .campaignName {
    @apply w-[14rem] truncate;

    font-size: 12px;
    font-weight: 600;
    margin-bottom: 6px;
  }
  .campaignSubname {
    font-size: 10px;
    font-weight: 500;
    color: #777;
    margin-bottom: 6px;
  }
  .domainWrapper {
    display: flex;
    gap: 4px;
  }
  .errorContainer {
    display: flex;
    gap: 6px;
    margin-top: 15px;
    ul {
      list-style-type: disc;
      margin-left: 14px;
    }
    .errorText {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      display: flex;
    }

    .errorList {
      color: #ea0f0f;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
  }
  .changeButton {
    color: #777;
    border-radius: 6px;
    border: 1px solid #777;
    padding: 7px 20px;
    gap: 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    transition-duration: 0.15s;
    height: min-content;
    margin-top: 4px;
    &:hover {
      background-color: #777;
      color: #fff;
    }
  }
}

.flowCard {
  border-radius: 12px;
  background: #fff;
  border: 1px solid #eaeaea;
  margin-top: 20px;

  .header {
    color: #000;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px; /* 150% */
    border-bottom: 1px solid #eaeaea;
    padding: 7px 20px;
  }
  .body {
    padding: 20px;
  }

  .connectCircle {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 100%;
    border: 1px solid #1c86fa;
    background-color: #fff;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .connectLineBottom {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 100%;
    border: 1px solid #1c86fa;
    background-color: #fff;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .titleContainer {
    display: flex;
    gap: 8px;
    margin-bottom: 5px;
    .title {
      color: #000;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
    }
  }
  .content {
    color: #777;
    font-size: 12px;
    font-weight: 400;
    padding-left: 26px;
    margin-bottom: 15px;
  }

  &.active {
    border: 1px solid #1c86fa;
    box-shadow: -5px 5px 10px 0px rgba(0, 0, 0, 0.1);

    .header {
      border-bottom: 1px solid #1c86fa;
    }
    .connectCircle {
      background-color: #1c86fa;
    }
  }

  .buttons {
    padding-left: 26px;

    button {
      padding: 7px 20px;
      font-size: 12px;
      line-height: 18px;
    }
  }
}
