.search_input {
  position: relative;
  input {
    -webkit-appearance: none;
    width: 100%;
    height: 42px;
    font-family: 'Inter';
    font-size: 12px;
    line-height: 150%;
    color: black;
    outline: 2px solid transparent;
    outline-offset: 2px;
    background-color: rgb(245 245 245);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    border-radius: 8px;
    padding-right: 40px;
    padding-left: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    border: 1px solid transparent;

    &:hover {
      background-color: rgb(234 234 234);
    }

    &::placeholder {
      color: #777777;
    }

    &:focus {
      background-color: transparent;
      border-color: rgb(28 134 250);
    }
  }
  .search_icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    color: #777777;
    cursor: pointer;

    &.active {
      color: black;
    }
  }
}
.icon_left {
  input {
    padding-left: 43px;
  }
  .search_icon {
    left: 15px;
    right: 100%;
  }
}
