@font-face {
  font-family: 'Ubuntu Mono';
  src: url('/fonts/UbuntuMono-Regular.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
.container {
  background: #222;
  border-radius: 8px;
  .buttonWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 14px 14px 14px;
    width: 100%;
    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: #ffffff;
      border-radius: 4px;
      padding: 5px 12px;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      .icon {
        margin-right: 5px;
      }
      .text {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
  &:global(.embed-code) > div:last-child {
    justify-content: flex-start;
    margin-left: 8px;
  }

  .code {
    pre {
      margin-bottom: 4px;
      code {
        * {
          font-family: 'Ubuntu Mono', 'Inter' !important;
          font-style: normal !important;
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 16px !important;
        }
        span {
          color: #81e985 !important;
        }

        span:nth-child(1),
        span:nth-child(2),
        span:nth-last-child(1),
        span:nth-last-child(2),
        span:nth-last-child(3),
        span:nth-last-child(4) {
          color: white !important;
        }
        span:nth-child(4),
        span:nth-child(8),
        span:nth-child(12),
        span:nth-child(14) {
          color: #f8d06b !important;
        }
      }
    }
  }
}
