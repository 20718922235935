@-webkit-keyframes fade_in_scale {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes fade_in_scale {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.pps-fade_in_scale_effect {
  -webkit-animation: fade_in_scale 1s linear both;
  animation: fade_in_scale 1s linear both;
}
