$duration: 1s;
$translateX: 100%;
$timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);

@-webkit-keyframes slide_in_right {
  from {
    -webkit-transform: translateX($translateX);
    transform: translateX($translateX);
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slide_in_right {
  from {
    -webkit-transform: translateX($translateX);
    transform: translateX($translateX);
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.pps-slide_in_right_effect {
  -webkit-animation: slide_in_right $duration $timing-function both;
  animation: slide_in_right $duration $timing-function both;
}
