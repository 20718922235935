.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  .headline {
    display: flex;
    ul {
      width: 100%;
      margin-bottom: 24px;
      border-collapse: collapse;
      border-bottom: 1px solid #eaeaea;
      li {
        position: relative;
        float: left;
        font-weight: 600;
        font-size: 14px;
        color: #999999;
        border-bottom: 1px solid transparent;
        transition: color 300ms;
        &:hover {
          color: #000;
          cursor: pointer;
        }
        & > span {
          display: block;
          padding: 10px 12.5px;
        }
      }
      li.small {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        margin-right: 8px;
        & > span {
          display: block;
          padding: 0px 5px 10px 5px;
        }
        &:first-child {
          padding-left: 0px;
        }
        &:last-child {
          margin-right: 0px;
        }
      }
      li.colorSmall {
        & > span {
          padding: 0px 5px 0 5px !important;
        }
      }
      li.active {
        color: #000;
        border-bottom: 1px solid black;
      }
      :global(.imageCardTooltipWrapper) {
        li.small {
          padding: 0px 5px 10px 5px;
        }
        &:last-child li.small {
          margin-right: 0px;
        }
        &:first-child li.small {
          margin-right: 0px;
        }
      }

      .unClickable {
        &:hover {
          cursor: initial;
          color: #999999;
          box-shadow: none;
        }
      }
    }
  }
}
