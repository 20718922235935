@-webkit-keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(0.8) rotateZ(0);
    transform: scale(0.8) rotateZ(0);
  }
  50% {
    -webkit-transform: scale(1) rotateZ(180deg);
    transform: scale(1) rotateZ(180deg);
  }
  100% {
    -webkit-transform: scale(0.8) rotateZ(360deg);
    transform: scale(0.8) rotateZ(360deg);
  }
}
@keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(0.8) rotateZ(0);
    transform: scale(0.8) rotateZ(0);
  }
  50% {
    -webkit-transform: scale(1) rotateZ(180deg);
    transform: scale(1) rotateZ(180deg);
  }
  100% {
    -webkit-transform: scale(0.8) rotateZ(360deg);
    transform: scale(0.8) rotateZ(360deg);
  }
}

.rotate-zoom-animation {
  -webkit-animation: rotate-scale-up 1.6s linear infinite both;
  animation: rotate-scale-up 1.6s linear infinite both;
}
