.radio {
  @apply flex items-center cursor-pointer select-none p-[6px] pl-0;
  &.disabled {
    @apply cursor-not-allowed;
  }
  input {
    @apply relative appearance-none w-[18px] h-[18px] aspect-square rounded-full border border-[#DDDDDD] transition-all duration-200 ease-in-out cursor-pointer;

    &::before {
      content: '';
      @apply absolute w-2 h-2 rounded-full bg-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
    }

    &:not(:disabled):hover {
      @apply border-bluesmart;
      box-shadow: 0 0 0 5px rgb(28 134 250 / 17%);
    }
    &:checked {
      @apply border-bluesmart bg-bluesmart relative;
      &::before {
        display: block;
      }
    }

    &:not(:checked).error {
      @apply border-redcold;
      &:not(:disabled):hover {
        @apply border-red;
        box-shadow: 0 0 0 5px rgb(234 15 15 / 17%);
      }
    }

    &:disabled {
      @apply bg-gray-100 cursor-not-allowed bg-no-repeat bg-center bg-cover;
      border-color: rgb(210 218 227) !important;
      background-image: url(/assets/icons/disabled-icon.svg);
    }
  }
  .label {
    @apply font-inter text-sm leading-[18px] text-gray-700 ml-[10px] cursor-pointer;
  }

  &.targeting_style {
    input {
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;

      &::before {
        width: 6px;
        height: 6px;
      }
    }
    .label {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      margin-left: 6px;
    }
  }
}
