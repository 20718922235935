.input {
  @apply py-[14px] pl-[15px] pr-3 font-inter text-base leading-lg rounded-lg border border-gray-400 outline-none transition-colors duration-200;
  &:not(:disabled):focus {
    @apply border-bluesmart;
  }
  &:not(:disabled):hover {
    @apply border-bluesmart;
    box-shadow: 0 0 0 5px rgb(28 134 250 / 17%);
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}
