.popupShareUrl {
  display: flex;
  flex-direction: column;
  padding: 5px 8px;
  .buttonWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: #EAEAEA;
      border-radius: 4px;
      padding: 5px 12px;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      .icon {
        margin-right: 5px;
      }
      .text {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}