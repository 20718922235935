@keyframes fade-out-up {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.pps-fade-out-up {
  animation: fade-out-up 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
