.iconWrapper {
  @apply flex relative w-max;
  .leftIcon {
    @apply z-[1] w-20 h-20 rounded-full border border-grayborder bg-inherit flex items-center justify-center;
  }
  .rightIcon {
    @apply relative right-2.5 z-[2] w-20 h-20 rounded-full border border-grayborder bg-white flex items-center justify-center;
  }
  &.small {
    .leftIcon,
    .rightIcon {
      @apply w-16 h-16;
    }
  }
}
