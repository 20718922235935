.themeContainer {
  display: flex;
  flex-direction: column;
  padding-top: 0;
  padding-right: 0;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 100%;
    .title {
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
    }
    .buttons {
      display: flex;
      flex-direction: row;
      gap: 10px;
      z-index: 1;
      button {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #1c86fa;
        &:disabled {
          color: #999999;
          cursor: not-allowed;
        }

        &.reset {
          color: #999999;

          &:hover {
            color: #000000;
          }
        }
      }
      .changeButton {
        background: #1c86fa;
        border-radius: 4px;
        padding: 3px 10px;
        color: #fff;
      }
    }
  }
  .currentTheme {
    display: flex;
    padding: 10px 0px;
  }
}
