.action {
  position: relative;
  margin-left: auto;
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: #f5f5f5;
    border-radius: 6px;
    transition: all 300ms ease-in-out;

    &:hover {
      background: #f2f2f2;
    }
  }
}

.dropdown {
  background: #ffffff;
  border: 1px solid #dddddd;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 6px 0px;
  position: absolute;
  z-index: 999;
  display: none;
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 7px 7px 10px;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    width: 100%;
    min-width: 140px;

    &:hover {
      background: #f5f5f5;
    }
    .actionIcon {
      margin-left: 4px;
    }

    &.disable {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  &.open {
    display: block;
  }
}
