.container {
  @apply relative px-5 py-7;
  display: grid;
  grid-template-columns: calc(100% - 425px) 420px;
  gap: 5px;
  padding: 20px 5px 16px 20px;
  @media only screen and (max-width: 960px) {
    display: block;
    grid-template-columns: 1fr;
    margin-top: 40px;
    padding: 20px 20px 16px 20px;
  }

  .left {
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 960px) {
      width: 100%;
    }
    .preview {
      background: #eaeaea;
      border-radius: 18px;
      border: 1px solid #e5e5e5;
      height: 561px;
      overflow-y: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      @media only screen and (max-width: 960px) {
        min-height: 420px;
        max-height: max-content;
        .previewLite {
          font-size: 11px;
        }
        .previewLiteMobile {
          font-size: 9px;
        }
      }
      @media only screen and (max-width: 820px) {
        .previewLite {
          font-size: 10px;
        }
      }
      @media only screen and (max-width: 640px) {
        min-height: auto;
        height: max-content;
        padding: 10px 5px;

        .previewLite {
          font-size: 5px;
        }
        .previewLiteMobile {
          font-size: 8px;
        }
      }
    }
    &.isFullScreenPageType {
      border-radius: var(--bodyBorderRadius);
    }
  }
  .right {
    margin: 24px 0px 8px 0px;
    padding-right: 50px;
    padding-left: 50px;
    max-height: 540px;
    overflow-y: auto;

    @media only screen and (max-width: 960px) {
      padding-right: 0px;
    }

    .title {
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
      color: #000000;
      margin-bottom: 16px;
    }

    .themeDropdown {
      position: relative;
      .currentTheme {
        position: relative;

        .changeTheme {
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
          width: auto;
          padding: 8px 15px;
          background: #000000;
          border-radius: 6px;
          font-family: 'Inter';
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: #ffffff;
          transition: opacity 400ms ease-in-out;

          &:hover {
            opacity: 0.8;
          }
        }
      }

      .themeListDropdown {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        top: 100%;
        margin-top: 10px;
        background: #ffffff;
        box-shadow: 0px 4px 20px -5px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
        z-index: 2;

        .header {
          display: flex;
          justify-content: space-between;
          .dropdownTitle {
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
            color: #000000;
            padding: 20px;
          }
          .resetButton {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #999999;
            margin-right: 20px;

            &:disabled {
              cursor: not-allowed;
            }

            &:hover {
              color: #000000;
            }
          }
        }

        .dropdownList {
          background: #ffffff;
          max-height: 280px;
          padding: 0px 20px 20px 20px;
          overflow-y: scroll;
          overflow-x: hidden;
        }
      }
    }
  }
}

.title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 16px;
}
